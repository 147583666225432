<template>
  <div class="box body-bg">
    <div class="content">
      <div class="item header">Neolix DBApi</div>
      <div class="item">
        <el-input v-model="username" :placeholder="$t('m.username')"></el-input>
      </div>
      <div class="item">
        <el-input
          v-model="password"
          :placeholder="$t('m.password')"
          type="password"
        ></el-input>
      </div>
      <div class="item">
        <div class="buttton" @click="login">{{ $t("m.login") }}</div>
      </div>
      <div style="position: relative; color: aliceblue">
        其他登录方式：<a @click.prevent="handleLink()">OIDC登录</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    login() {
      this.axios
        .post("/user/login", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          localStorage.setItem("access_token", response.data.access_token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          localStorage.setItem("username", response.data.nickname);
          localStorage.setItem("userId", response.data.id);
          this.$router.push("/api");
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    handleLink() {
      this.axios
        .get("/user/oidc")
        .then((response) => {
          // 单点登陆
          // 单点登陆 根据不同环境 设置不同返回端口 线上环境前后端未分离
          // redirect_url返回到前端请求
          let { errorCode, errorMsg, data } = response;
          if (process.env.NODE_ENV == "development") {
            var hostUrl =
              window.location.protocol +
              "//" +
              window.location.hostname +
              ":" +
              8521 +
              "/#/auth-redirect";
          } else if (process.env.NODE_ENV == "production") {
            var hostUrl =
              window.location.protocol +
              "//" +
              window.location.hostname +
              "/#/auth-redirect";
          }
          let redirectUrl =
            "&redirect_uri=" + encodeURIComponent(hostUrl) + "&";
          let url =
            data.sso +
            "?response_type=" +
            data.response_type +
            "&client_id=" +
            data.client_id +
            "&state=" +
            data.state +
            redirectUrl +
            "&login=" +
            data.login +
            "&scope=" +
            data.scope;
          if (errorCode == 1000) {
            window.location.href = url; //跳转
          } else {
            this.$message.error(errorMsg);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
  created() {
    //bind login event to enter key
    document.onkeydown = (e) => {
      const key = window.event.keyCode;
      if (key == 13 || key == 100) {
        this.login();
      }
    };
  },
  destroyed() {
    //unbind login event to enter key when go to other pages
    document.onkeydown = null;
  },
};
</script>

<style scoped>
.box {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  /*margin-top: 200px;*/
}

.content {
  /*box-shadow: 10px 10px 5px #888888;*/
  /*margin-top: -100px;*/
  padding-bottom: 100px;
}

.item {
  width: 400px;
  height: 70px;
}

.buttton {
  width: 400px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #273446;
  border-radius: 10px;
  color: #bfcbd9;
  font-weight: 700;
  font-size: 20px;
}

.header {
  text-align: center;
  color: #bfcbd9;
  font-weight: 700;
  font-size: 40px;
}

.buttton:hover {
  background-color: #0b0f14;
  font-size: 21px;
  cursor: pointer;
}

.body-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  background-image: radial-gradient(#166cd5, #222d3b);
  /*background-color: #5a7aa1;*/
  color: #bfcbd9;
}

a:hover {
  color: #0015ff;
  cursor: pointer;
}
</style>
