import { render, staticRenderFns } from "./request.vue?vue&type=template&id=f8ae95e0&scoped=true"
import script from "./request.vue?vue&type=script&lang=js"
export * from "./request.vue?vue&type=script&lang=js"
import style0 from "./request.vue?vue&type=style&index=0&id=f8ae95e0&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8ae95e0",
  null
  
)

export default component.exports