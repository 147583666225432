<template>
  <div>
    <el-form label-width="160px">
      <el-form-item :label="$t('m.datasource')">
        <el-select v-model="datasourceId">
          <el-option :value="item.id" :label="item.name" v-for="(item, index) in datasources" :key="index">{{ item.name }}</el-option>
        </el-select>
        <label-tip :tip="$t('m.redis_warning')"></label-tip>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>

import {EXECUTOR_TYPE} from "@/constant";
import sqlide from "@/components/api/executor/sqlIDE.vue";

export default {
  name: "RedisExecutor",
  data() {
    console.log("RedisExecutor data");
    return {
      datasourceId: null,
      datasources: []
    }
  },
  props: {
    redisExecutorDetail: {
      type: Object,
      default: {}
    }
  },
  methods: {
    getTaskJson() {
      console.log("redis getTaskJson", this.datasourceId)
      return {
        taskType: EXECUTOR_TYPE.REDIS_EXECUTOR,
        datasourceId: this.datasourceId
      }
    },
    check() {
      if (this.datasourceId == null) {
        this.$message.warning("REDIS Executor: redis sources empty!")
        return false
      }
      return true
    },
    getAllSource() {
      this.axios.get("/datasource/getAll").then((response) => {
        this.datasources = response.data
      }).catch((error) => {
        this.$message.error("Get all datasources Failed")
      })
    }
  },
  watch: {
    // 编辑API页面，本组件生成的时候，props还没注入进来，所以要监听
    redisExecutorDetail: {
      handler(newVal, oldVal) {
        console.log("redisExecutorDetail>>>>>")
        this.transaction = newVal.transaction
        this.datasourceId = newVal.datasourceId
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getAllSource()
    this.getAllPlugins()
  }
}
</script>
