<template>
  <div>
    <el-form label-width="160px">
      <el-form-item :label="$t('m.datasource')">
        <el-select v-model="datasourceId">
          <el-option :value="item.id" :label="item.name" v-for="(item, index) in datasources" :key="index">{{ item.name }}</el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div slot="label">
          <label-tip label="SQL" :tip="$t('m.sql_warning')"></label-tip>
        </div>
        <div>
          <el-tabs ref="tabs" v-model="currentActiveTabName" type="border-card"
            @tab-click="tabClick"
            @edit="handleTabsEdit"
            tab-position="top">
            <!-- <el-tab-pane :key="item.name" v-for="(item, index) in editableTabs" :label="'SQL-'+item.name" :name="item.name"> -->
            <el-tab-pane :key="item.name" v-for="(item, index) in editableTabs" :name="item.name">
              <!-- 通过v-if进行判断 -->
              <span class="tab-label-edit" slot="label" :id="'tabPaneSpan-'+item.name" v-show="item.isEditedLabel">
                <el-input :id="'tabPaneInput-'+item.name" class="tab-pane-input"
                  v-model="item.sqlName"
                  @keydown.native.enter="keydownEnterClick(item)"
                  @blur="inputBlur(item)">
                </el-input>
              </span>
              <div :id="'tabPaneLabel-'+item.name" style="display: inline-block;" v-show="!item.isEditedLabel" slot="label">
                <span> {{item.sqlName}} </span>
                <span class="el-icon-close" @click="handleTabsEdit(item.name, 'remove')"></span>
              </div>
              <sqlide ref="codemirror" :textareaRef="'cms'+index" :value="item.sqlText" :ds="datasourceId"></sqlide>
              <div style="margin-top: 10px">
                <label-tip :label="$t('m.transform')" :tip="$t('m.transform_plugin_warning')"></label-tip>
                <span>SQL-{{ item.name }} : </span>
                <span class="label">{{ $t('m.plugin_name') }}</span>
                <el-select v-model="item.transformPlugin" style="width:400px" clearable @clear="item.transformPluginParam = null;" :no-data-text="$t('m.no_plugin')">

                  <el-option v-for="(op, index) in transformPlugins" :key="index" :value="op.className" :label="op.name">
                    <div>
                      <el-tooltip placement="top-start" effect="dark">
                        <div slot="content">
                          <div>{{ $t('m.plugin_desc') }}：{{ op.description }}</div>
                          <div>{{ $t('m.plugin_param_desc') }}：{{ op.paramDescription }}</div>
                        </div>
                        <div>
                          <span>{{ op.name }}</span>
                          <span style="color: #cccccc;margin-left:10px;">{{ op.className }} </span>
                        </div>
                      </el-tooltip>
                    </div>
                  </el-option>
                </el-select>
                <span class="label">{{ $t('m.plugin_param') }}</span>
                <el-input v-model="item.transformPluginParam" style="width:400px"></el-input>
              </div>
            </el-tab-pane>
          </el-tabs>

        </div>
      </el-form-item>
      <el-form-item>
        <div slot="label">
          <label-tip :label="$t('m.transaction')" :tip="$t('m.transaction_tip')"></label-tip>
        </div>

        <el-radio-group v-model="transaction">
          <el-radio :label="1">{{ $t('m.on') }}</el-radio>
          <el-radio :label="0">{{ $t('m.off') }}</el-radio>
        </el-radio-group>

      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import sqlide from "@/components/api/executor/sqlIDE.vue";
import {EXECUTOR_TYPE} from "@/constant";

export default {
  name: "SqlExecutor",
  data() {
    console.log("SqlExecutor data");
    return {
      transformPlugins: [],
      transaction: 0,
      currentActiveTabName: '1', //当前选中的tab的name
      currentActiveTabIndex: 0, // 当前选中tab的索引值
      editableTabs: [{isEditedLabel: false, sqlName: 'X-SQL-1', name: '1', sqlText: "", transformPlugin: null, transformPluginParam: null}],
      tabIndex: 1, //tab 总数
      datasourceId: null,
      datasources: [],
    }
  },
  props: {
    sqlExecutorDetail: {
      type: Object
    }
  },
  methods: {
    tabClick(tab, event) {// 双击label事件
      if (tab.index != this.tabClickId) {
        this.tabClickCnt = 0;
      }
      this.tabClickId = tab.index;
      this.tabClickCnt++;

      // 注册清空点击次数计时器任务
      setTimeout(() => {
        this.tabClickCnt = 0;
      }, 300);

      if (this.tabClickCnt >= 2) {// 点击大于2次时
        this.labelEditClick(tab.index, this.editableTabs[tab.index]);
      }
    },
    labelEditClick(tabPaneIndex, data) {
      this.editableTabs[tabPaneIndex].isEditedLabel = true
      setTimeout(() => {
        document.getElementById("tabPaneLabel-"+data.name).style.display = 'none'
        document.getElementById("tabPaneSpan-"+data.name).style.display = 'inline-block'
        document.getElementById("tabPaneInput-"+data.name).focus()
      },200)
    },
    keydownEnterClick(data) {// 回车事件
      data.isEditedLabel = false
      document.getElementById("tabPaneLabel-"+data.name).style.display = 'inline-block'
      document.getElementById("tabPaneSpan-"+data.name).style.display = 'none'
    },
    inputBlur(data) {// 失去焦点
      this.keydownEnterClick(data)
    },
    getAllPlugins() {
      this.axios
        .get("/plugin/all")
        .then((response) => {
          this.transformPlugins = response.data.transform;
        })
        .catch((error) => {
        });
    },
    getTaskJson() {
      let sqls = this.$refs.codemirror.map((item, index) => item.coder.getValue())
      let p = this.editableTabs.map((item, index) => {
        return {
          sqlName: item.sqlName == null ? "X-SQL-" + (index + 1) : item.sqlName,
          sqlText: sqls[index],
          transformPlugin: item.transformPlugin,
          transformPluginParam: item.transformPluginParam
        }
      })
      // console.log(p)
      return {
        taskType: EXECUTOR_TYPE.SQL_EXECUTOR,
        sqlList: p,
        transaction: this.transaction,
        datasourceId: this.datasourceId
      }
    },
    check() {
      if (this.datasourceId == null) {
        this.$message.warning("SQL Executor: datasource empty!")
        return false
      }
      let sqls = this.$refs.codemirror.map((item, index) => item.coder.getValue())
      for (let sql of sqls) {
        console.log(sql, sql.trim())
        if (sql.trim() == '') {
          this.$message.warning("SQL Executor: SQL empty!")
          return false
        }
      }
      return true
    },
    getAllSource() {
      this.axios.get("/datasource/getAll").then((response) => {
        this.datasources = response.data
      }).catch((error) => {
        this.$message.error("Get all datasources Failed")
      })
    },
    handleTabsEdit(targetName, action) {
      if (action === 'add') {//默认显示1个，所以再次新增需要先加
        let newTabName = ++this.tabIndex + '';
        this.editableTabs.push({
          name: newTabName,
          sqlName: 'SQL-' + newTabName,
          sqlText: "",
          transformPlugin: null,
          transformPluginParam: null
        });
        this.currentActiveTabName = newTabName;
      } else if (action === 'remove') {
        if (this.editableTabs.length === 1) {
          this.$message.warning("At least one tab!")
          return;
        }
        let tabs = this.editableTabs;
        let activeName = this.currentActiveTabName;
        let i = 0;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              i = index;
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }

        this.currentActiveTabName = activeName;
        this.editableTabs = tabs.filter(tab => tab.name !== targetName);
        // this.$store.commit('removeCm', i) // 删除 vuex中 的cmInstance
      }
    },
    onAddTabPanel(event) { // 只有双击这个div才能执行添加
      if (event.target.className.indexOf('el-tabs__nav-scroll') != -1) {
        this.handleTabsEdit('', 'add') // 添加标签
      }
    },
  },
  components: {
    sqlide
  },
  watch: {
    // 编辑API页面，本组件生成的时候，props还没注入进来，所以要监听
    sqlExecutorDetail: {
      handler(newVal, oldVal) {
        console.log("sqlExecutorDetail....")
        this.transaction = newVal.transaction
        this.datasourceId = newVal.datasourceId
        // 生成子组件中的tabPane需要的数据格式
        for (let j = 0; j < newVal.sqlList.length; j++) {
          const b = newVal.sqlList[j]
          b.name = (j + 1) + ''
          if (b.isEditedLabel == null) {
            b.isEditedLabel = false
          }
          if (b.sqlName == null) {
            b.sqlName = "AASQLAA-" + (j + 1)
          }
        }
        this.editableTabs = newVal.sqlList;
        this.tabIndex = newVal.sqlList.length;
      },
      deep: true,
      immediate: true
    },
    editableTabs(newV, oldV) {
      console.log("aaaa")
      this.editableTabs.forEach((tab, index) => {
        if (tab.name === this.currentActiveTabName) {
          this.currentActiveTabIndex = index;
        }
      });
    },
    currentActiveTabName(newV, oldV) {
      this.editableTabs.forEach((tab, index) => {
        if (tab.name === this.currentActiveTabName) {
          this.currentActiveTabIndex = index;
        }
      });
    }
  },
  mounted() {
    var tabNav = document.getElementsByClassName('el-tabs__nav-scroll')[1];
    tabNav.addEventListener('dblclick', this.onAddTabPanel);
  },
  computed: {

  },
  created() {
    this.getAllSource();
    this.getAllPlugins()
  }
}
</script>

<style scoped>
.label {
  font-weight: 700;
  margin: 0 5px 0 20px;
}
.el-icon-edit:hover {
  color:rgb(0, 136, 255);
}

.tab-label-edit > .el-icon-check:hover {
  color:rgb(100, 233, 73);
}


.tab-pane-input>>>.el-input__inner {
    border: 1px solid;
    height: 33px;
    line-height: 33px;
}

</style>