<template>
  <div v-loading.fullscreen.lock="loading"
       element-loading-text="正在加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.2)"></div>
</template>

<script>
export default {
  name: 'AuthRedirect',
  data() {
    return {
      loading: true
    };
  },
  // watch: {
  //   $route(to, from) {
  //     console.log('onroute', this.$route.query)
  //     console.log(to, from)
  //     this.$router.go(0)
  //   }
  // },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 20 * 1000);
    this.handleOidcCallback(new URLSearchParams(location.search))
  },
  created() {
  },
  methods: {
    handleOidcCallback(query) {
      this.$store.dispatch('oidcCallback', query)
        .then(() => {
          window.location.href = '/#/api'
          // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
          // this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  render: function(h) {
    return h() // avoid warning message
  }
}
</script>
