import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mode: null,
  },
  mutations: {
    setMode(state, payload) {
      state.mode = payload;
    },
  },
  actions: {
    // oidc callback
    oidcCallback({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        if (process.env.NODE_ENV == "development") {
          var redirectUrl =
            window.location.protocol +
            "//" +
            window.location.hostname +
            ":" +
            8521 +
            "/#/auth-redirect";
        } else if (process.env.NODE_ENV == "production") {
          var redirectUrl =
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/#/auth-redirect";
        }
        queryParams.append("redirect_url", redirectUrl);
        Vue.prototype.axios
          .get("/user/oidc/callback", { params: queryParams })
          .then((response) => {
            console.log("callback response:", response);
            let { errorCode, errorMsg, data } = response;
            if (errorCode == 1000) {
              localStorage.setItem("access_token", data.access_token);
              localStorage.setItem("refresh_token", data.refresh_token);
              localStorage.setItem("nickname", data.nickname);
              localStorage.setItem("username", data.username);
              //localStorage.setItem("userId", data.id);
            } else {
              this.$message.error(errorMsg);
            }
            // commit('SET_TOKEN', data.access_token)
            // setToken(data.access_token)
            // setRefreshToken(data.refresh_token)
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    },
  },
  getters: {},
});
