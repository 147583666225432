<template>
  <div class="tree_root">
    <el-button type="primary" icon="el-icon-circle-plus" @click="createDialog = true" size="mini" plain>{{ $t('m.create_group') }}</el-button>

    <el-button type="primary" icon="el-icon-refresh" circle @click="getAllApiTree" size="mini" plain></el-button>

    <el-tree :data="data" node-key="id" :expand-on-click-node="true" accordion :props="defaultProp"
            @node-expand="handleNodeExpand"
            @node-collapse="handleNodeCollapse"
            @node-click="handleNodeClick">
        <span class="custom-tree-node" slot-scope="{ node, data }">
<!--          <div class="left">-->
            <span class="left" >

              <span class="el-icon-folder-opened title" v-if="data.type=='group'" :title="node.label"> {{ node.label }}</span>
              <span class="iconfont icon-api title" :class="{disable:data.status === 0}" v-if="data.type=='api'" :title="node.label"> {{ node.label }}</span>
            </span>
<!--            <span class="iconfont icon-api"  v-if="data.type=='api'">{{ node.label }}</span>-->
<!--          </div>-->

<!--          <div class="right">-->
            <span class="align:right" v-if="data.type=='api'">
              <el-dropdown size="medium" @command="handleCommand">
                <i class="el-icon-more-outline"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="{type: 'edit', path: '/api/edit', id: data.id}">
                      <i class="el-icon-edit"></i>
                  </el-dropdown-item>

                  <el-dropdown-item v-if="data.status == 0" :command="{node: node, type: 'online', id: data.id}">
                    <i class="el-icon-top"></i>
                  </el-dropdown-item>

                  <el-dropdown-item v-if="data.status == 1" :command="{node: node, type: 'offline', id: data.id}">
                    <i class="el-icon-bottom"></i>
                  </el-dropdown-item>

                  <el-dropdown-item v-if="data.status == 1" :command="{type: 'execute_test', path: '/api/request', id: data.id}">
                    <i class="iconfont icon-HTTPRequest"></i>
                  </el-dropdown-item>

                  <el-dropdown-item :command="{node: node, type: 'delete', data: data}">
                    <i class="el-icon-delete" style="color: #c50303"></i>
                  </el-dropdown-item>

                </el-dropdown-menu>
              </el-dropdown>
            </span>
            <span class="align:right" v-if="data.type=='group'">
<!--              <el-tooltip :open-delay="500" class="item" effect="light" content="Delete Group" placement="top" v-if="data.children.length == 0">-->
<!--                <i class="el-icon-delete" @click="deleteGroup(data.id)" style="color: #c50303"></i>-->
<!--              </el-tooltip>-->
              <el-tooltip :open-delay="500" class="item" effect="light" placement="right">
                <i class="el-icon-circle-plus" @click="$router.push({path: '/api/add', query: {groupId: data.id}});"></i>
              </el-tooltip>
              <el-dropdown size="medium">
                  <i class="el-icon-more-outline"></i>
                <el-dropdown-menu slot="dropdown">
                   <el-dropdown-item @click.native="editGroupDialog=true;handleEditGroup(data);">
                    <i class="el-icon-edit"></i>
                  </el-dropdown-item>

                  <el-dropdown-item v-if="data.children.length == 0" @click.native="deleteGroup(data.id)">
                    <i class="el-icon-delete" style="color: #c50303"></i>
                  </el-dropdown-item>

                </el-dropdown-menu>
              </el-dropdown>
            </span>
<!--          </div>-->
        </span>
    </el-tree>

    <el-dialog :title="$t('m.create_group')" :visible.sync="createDialog">
      <el-form label-width="100px">
        <el-form-item :label="$t('m.name')">
          <el-input v-model="groupName"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="createDialog = false">{{ $t('m.cancel') }}</el-button>
        <el-button type="primary" @click="createDialog = false;createGroup()">{{ $t('m.save') }}</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="$t('m.edit_group')" :visible.sync="editGroupDialog">
      <el-form label-width="100px">
        <el-form-item :label="$t('m.name')">
          <el-input v-model="groupDetail.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editGroupDialog = false">{{ $t('m.cancel') }}</el-button>
        <el-button type="primary" @click="editGroupDialog = false;editGroup()">{{ $t('m.save') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ApiTree",
  data() {
    return {
      data: [],
      defaultShowNodes: [], // 这里存放要默认展开的节点 id
      groupName: null,
      defaultProp: {children: 'children', label: 'name'},
      createDialog: false,
      editGroupDialog: false,
      groupDetail:{
        id:null,
        name:null
      }
    }
  },
  methods: {
    handleCommand(command) {
      switch (command.type) {
        case 'edit':
          this.$router.push({path: command.path, query: {id: command.id}});
          break;
        case 'online':
          this.online(command.node, command.id)
          break;
        case 'offline':
          this.offline(command.node, command.id)
          break
        case 'execute_test':
          this.$router.push({path: command.path, query: {id: command.id}});
          break
        case 'delete':
          this.deleteAPI(command.node, command.data)
          break
      }
    },
    editGroup(){
      this.axios
        .post("/group/update/",this.groupDetail)
        .then((response) => {
          this.getAllApiTree();
        })
        .catch((error) => {
          this.$message.error("Edit Group Failed");
        });
    },
    handleEditGroup(data){
      this.groupDetail.id = data.id
      this.groupDetail.name = data.name
    },
    deleteAPI(node, data) {
      this.axios
        .post("/apiConfig/delete/" + data.id)
        .then((response) => {
          this.$message.success("Delete Success");
          //this.getAllApiTree(); 不在强制刷新tree
          this.deleteTreeNode(node, data)
        })
        .catch((error) => {
          console.log(error)
          this.$message.error("Delete api Failed");
        });
    },
    online(node, id) {
      this.axios
        .post("/apiConfig/online/" + id)
        .then((response) => {
          this.$message.success("Publish Success");
          //this.getAllApiTree(); 不在强制刷新整个tree
          let newValue = {}
          Object.assign(newValue, node.data)
          newValue.status = 1 //在线
          this.updateTreeNode(node, newValue)
        })
        .catch((error) => {
          this.$message.error("Publish Failed");
        });
    },
    offline(node,id) {
      this.axios
        .post("/apiConfig/offline/" + id)
        .then((response) => {
          this.$message.success("Offline Success");
          //this.getAllApiTree(); 不在强制刷新整个tree
          let newValue = {}
          Object.assign(newValue, node.data)
          newValue.status = 0 //离线
          this.updateTreeNode(node, newValue)
        })
        .catch((error) => {
          this.$message.error("Offline Failed");
        });
    },
    createGroup() {
      this.axios.post("/group/create/", {name: this.groupName}).then((response) => {
        this.getAllApiTree()
      }).catch((error) => {
        this.$message.error(error)
      })
    },
    deleteGroup(id) {
      this.axios.get("/group/delete/" + id).then((response) => {
        this.getAllApiTree()
      }).catch((error) => {
        this.$message.error(error)
      })
    },
    getAllApiTree() {
      this.axios
        .get("/apiConfig/getApiTree")
        .then((response) => {
          this.data = response.data;
        })
        .catch((error) => {
          // this.$message.error("查询所有api失败")
        });
    },
    updateTreeNode(node, newValue) {
      if (newValue != null) {
        node.data = newValue
      }
    },
    deleteTreeNode(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.id === data.id);
      //console.log(index, data.id)
      children.splice(index, 1);
    },
    // 树节点展开
    handleNodeExpand (data) {
      // 保存当前展开的节点
      let flag = false
      this.defaultShowNodes.some(item => {
        if (item === data.id) { // 判断当前节点是否存在， 存在不做处理
          flag = true
          return true
        }
      })
      if (!flag) { // 不存在则存到数组里
        this.defaultShowNodes.push(data.id)
      }
    },
    // 树节点关闭
    handleNodeCollapse (data) {
      // 删除当前关闭的节点
      this.defaultShowNodes.some((item, i) => {
        if (item === data.id) {
          this.defaultShowNodes.splice(i, 1)
        }
      })
    },
    // 叶子节点双击事件
    handleNodeClick (data) {
      // 如果点击的id不相同，则重置点击次数
      if (data.children !== undefined) {
        return // 非叶子节点 不处理
      }
      if (data.id != this.treeClickId) {
        this.treeClickCnt = 0;
      }
      this.treeClickId = data.id;
      this.treeClickCnt++;

      // 注册清空点击次数计时器任务
      window.clearTimeout(this.treeClickTimeout);
      this.treeClickTimeout = window.setTimeout(() => {
        this.treeClickCnt = 0;
      }, 300);

      // 点击一次时单击事件
      if (this.treeClickCnt >= 2) {// 单击事件
        this.$router.push({path: '/api/request', query: {id: data.id}});
      }
    }
  },
  created() {
    this.getAllApiTree();
  }
}
</script>

<style scoped lang="less">
.tree_root {
  box-shadow: 0px 0px 3px 2px rgba(196, 194, 194, 0.34);
  height: calc(100vh - 100px);
  font-size: 18px;
  padding: 5px ;
  overflow: auto;
  //border-radius: 5px;

  i {
    padding: 0 5px;
    font-size: 20px;

    &:hover {
      font-weight: bold;
    }
  }

  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    //padding-right: 8px;

    .left{

        width: 220px;
        //background-color: #ec8282;
        overflow: hidden; // 文字超长隐藏
        text-overflow: ellipsis; // 显示...
        white-space: nowrap; // 单行显示

    }
    .right{
      width: 50px;
      background-color: #ec5d5d;
      //text-align: right;
    }

    .disable {
      text-decoration: line-through;
      color: #cccccc;
    }
  }
}
</style>